import React from 'react'

function MBLogo({ height }) {
  return (
    <svg
      height={height || '40px'}
      strokeMiterlimit="10"
      style={{
        fillRule: 'nonzero',
        clipRule: 'evenodd',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
      }}
      version="1.1"
      viewBox="0 0 1024 1024"
    >
      <path
        d="M125.695 548.836C125.695 638.786 124.992 709.567 122.18 719.889L82.1054 749.381L81.4023 752.331C105.307 752.331 134.132 750.119 159.443 750.119C187.565 750.119 214.985 752.331 238.186 752.331L239.592 749.381L205.845 720.627C202.33 707.355 202.33 562.844 202.33 489.114C223.422 473.631 244.514 464.784 259.981 464.784C300.056 464.784 319.742 498.699 319.742 525.242C319.742 632.888 319.039 704.406 316.226 719.889L281.776 749.381L280.37 752.331C305.68 752.331 332.397 750.119 359.113 750.119C383.018 750.119 408.328 752.331 431.529 752.331L433.638 749.381L397.079 720.627L397.079 548.098C397.079 528.191 395.673 501.649 392.158 491.326C416.765 472.157 435.748 464.784 456.84 464.784C490.587 464.784 513.788 497.225 513.788 528.191C513.788 634.363 511.679 694.084 510.273 719.152L480.041 749.381L479.338 752.331C503.945 752.331 529.255 750.119 554.566 750.119C581.985 750.119 608.702 752.331 636.121 752.331L636.824 749.381L606.593 727.262C596.75 719.889 591.125 716.203 591.125 712.516L591.125 527.454C591.125 469.207 560.893 419.808 502.539 419.808C467.386 419.808 432.232 442.665 389.345 477.318C378.799 444.139 350.677 419.808 309.899 419.808C277.558 419.808 238.889 445.614 200.22 475.843L199.517 475.843L202.33 419.808C184.753 419.808 107.416 440.453 95.4637 449.3L93.3545 452.249L125.695 472.157L125.695 548.836Z"
        fill="#b83854"
        fillRule="nonzero"
        opacity="1"
        stroke="none"
      />
      <path
        d="M636.979 758.525C649.634 758.525 685.49 747.466 705.879 733.457C729.08 748.203 746.217 760 758.17 760C826.367 760 940 706.914 940 579.362C940 473.928 857.274 426.934 786.265 426.934C763.766 426.934 733.299 441.486 713.613 454.021C713.613 387.664 714.316 306.561 715.722 227.669C679.163 228.407 610.965 242.415 594.795 250.526L593.388 252.738L634.869 273.382C636.275 338.264 636.979 407.571 636.979 473.928L636.979 758.525ZM713.613 466.555C722.05 462.868 738.923 455.495 754.391 455.495C826.103 455.495 857.741 522.589 857.741 591.158C857.741 685.533 805.714 732.72 755.797 732.72C739.626 732.72 725.565 729.771 713.613 723.872L713.613 466.555Z"
        fill="#b83854"
        fillRule="nonzero"
        opacity="1"
        stroke="none"
      />
    </svg>
  );
}

export default MBLogo