import { useState, useEffect } from 'react';

function useIsClient() {
  const [isClient, setClient] = useState(false);
  const key = isClient ? "client" : 'server';

  useEffect(() => {
    setClient(true);
  }, []);

  return { isClient, key };
}

export default useIsClient;