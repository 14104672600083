exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-tags-jsx": () => import("./../../../src/pages/tags.jsx" /* webpackChunkName: "component---src-pages-tags-jsx" */),
  "component---src-templates-author-list-jsx": () => import("./../../../src/templates/author-list.jsx" /* webpackChunkName: "component---src-templates-author-list-jsx" */),
  "component---src-templates-blog-post-list-jsx": () => import("./../../../src/templates/blog-post-list.jsx" /* webpackChunkName: "component---src-templates-blog-post-list-jsx" */),
  "component---src-templates-category-list-jsx": () => import("./../../../src/templates/category-list.jsx" /* webpackChunkName: "component---src-templates-category-list-jsx" */),
  "component---src-templates-single-author-jsx": () => import("./../../../src/templates/single-author.jsx" /* webpackChunkName: "component---src-templates-single-author-jsx" */),
  "component---src-templates-single-blog-post-jsx": () => import("./../../../src/templates/single-blog-post.jsx" /* webpackChunkName: "component---src-templates-single-blog-post-jsx" */),
  "component---src-templates-single-category-jsx": () => import("./../../../src/templates/single-category.jsx" /* webpackChunkName: "component---src-templates-single-category-jsx" */)
}

